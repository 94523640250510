/* eslint-disable no-undef */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist";
import api from "@/lib/api";

export const getProfile = createAsyncThunk("account/fetch", async (_, { rejectWithValue }) => {
  try {
    const response = await api.get("/api/account");
    return response.data;
  } catch (err) {
    return rejectWithValue(err);
  }
});

export const login = createAsyncThunk("account/login", async (data) => {
  try {
    const response = await api.post("/token", data);
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const updateAvatar = createAsyncThunk("account/updateAvatar", async ({ id, url }) => {
  try {
    await api.put("/api/account/avatar/" + id, { url });
    return { url };
  } catch (err) {
    console.error(err);
  }
});

export const updateCurrentAvatar = createAsyncThunk("account/updateCurrentAvatar", async ({ url }) => {
  try {
    await api.post("/api/account/avatar", { url });
    return { url };
  } catch (err) {
    console.error(err);
  }
});

export const updateProfile = createAsyncThunk("account/update", async (data) => {
  try {
    const response = await api.put("/api/account", data);
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const changePassword = createAsyncThunk("account/update-password", async (data) => {
  try {
    const response = await api.post("/api/account/password", data);
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

export const resetPassword = createAsyncThunk("account/reset-password", async (data) => {
  try {
    const response = await api.post("/api/account/resetPassword", data);
    return response.data;
  } catch (err) {
    console.error(err);
  }
});

const initialState = {
  profile: {},
  isLoggedIn: false,
  updatingProfile: false,
};

const accountSlide = createSlice({
  name: "account",
  initialState,
  reducers: {
    loggout(state) {
      state = initialState;
      localStorage.removeItem("UserId");
      localStorage.removeItem("access_token");
      return state;
    },
    [REHYDRATE]: (state) => {
      console.log(state);
    },
    setLoggedIn: (state, { payload }) => {
      state.isLoggedIn = payload;
    },
  },
  extraReducers: (builder) => {
    //token ko hợp lệ hoặc đã hết hạn
    builder.addCase(login.fulfilled, (state, { payload }) => {
      console.log(payload);
      return state;
    });
    builder.addCase(getProfile.fulfilled, (state, { payload }) => {
      state.profile = payload;
      localStorage.setItem("UserId", payload.id);
      return state;
    });
    builder.addCase(updateCurrentAvatar.fulfilled, (state, { payload }) => {
      state.profile.avatar = payload.url;
    });
    builder.addCase(updateProfile.pending, (state, action) => {
      return { ...state, ...action.payload, updatingProfile: true };
    });
    builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
      return { ...state, profile: payload, updatingProfile: false };
    });
    builder.addCase(updateProfile.rejected, (state) => {
      return { ...state, updatingProfile: false };
    });
    builder.addCase(changePassword.pending, (state, action) => {
      return { ...state, ...action.payload, updatingPass: true };
    });
    builder.addCase(changePassword.fulfilled, (state) => {
      return { ...state, updatingPass: false };
    });
    builder.addCase(changePassword.rejected, (state) => {
      return { ...state, updatingPass: false };
    });
  },
});

export const { loggout, setLoggedIn } = accountSlide.actions;
export const blacklist = ["account.updatingPass", "account.updatingProfile"];
export default accountSlide.reducer;
