import { getProfile, loggout } from "@/reducers/account";
import { getEnums, getOptions } from "@/reducers/app";
import { Suspense, useEffect, useContext, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Layout,
  Menu,
  Avatar,
  App,
  Spin,

} from "antd";
const { Content, Sider } = Layout;
import { Link, Outlet, useNavigate } from "react-router-dom";
import logo from "@/asset/img/logo.png";
import logoFull from "@/asset/img/logo-full.png";
import { Icon } from "@/controls";
import ChervonDoubleRightIcon from "@/components/icons/chervon-double-right-icon";
import ChervonDoubleLeftIcon from "@/components/icons/chervon-left-icon";
import { useCurrentPath, useMenu, useSignal } from "@/hooks";
import { routes } from "@/routes";
import { onMessageListener } from "@/lib/firebase";
import Stringee from "@/components/call";
import { isSupported, getToken } from "firebase/messaging";
import messaging, { firebaseKey } from "@/lib/firebase";
import { getRoles, getRoleOptions } from "@/reducers/role";
import AppContext from "@/context/app";
import api from "../lib/api";
import { HomeOutlined } from "@ant-design/icons";

export default function AppContent() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sinalR = useSignal();
  const options = useSelector((state) => state.app.options);

  const account = useSelector((state) => state.account);
  const { collapsed, setCollapsed } = useContext(AppContext);

  const clearDeviceToken = async () => {
    if (window.deviceToken)
      await api.delete(`/api/notifications/token/${window.deviceToken}`);
  };

  const handleLogout = async () => {
    await clearDeviceToken();
    dispatch(loggout());
    navigate("/auth/login");
  };

  const handleClick = () => {
    navigate("/settings/profile");
  };


  const initFirebase = async () => {
    if (await isSupported()) {
      const token = await getToken(messaging, {
        validKey: firebaseKey,
      });

      window.deviceToken = token;
      try {
        await api.post("/api/notifications/token", {
          token,
          userId: account.profile.id,
          // this is device type, 0 is web-browser
          type: 0,
        });
      } catch {
        //
      }
    }
  };


  const UpdateOptions = (message) => {
    dispatch(getOptions());
  }

  sinalR({
    Logout: handleLogout,
    UpdateOptions: UpdateOptions,
    test: (e) => console.log('test', e)
  });

  useEffect(() => {
    initFirebase();

    onMessageListener()
      .then((payload) => {
        console.log({ notificationPayload: payload });
      })
      .catch((err) => console.log("failed: ", err));
  }, []);

  useEffect(() => {
    if (!account.isLoggedIn || !localStorage.getItem("access_token")) {
      navigate("/auth/login");
    } else {
      dispatch(getProfile());
      dispatch(getEnums());
      dispatch(getOptions());
      dispatch(getRoles());
      dispatch(getRoleOptions());
    }
  }, [account.isLoggedIn]);

  const menuItems = useMenu(routes[1].children);
  const currentPath = useCurrentPath(routes[1].children);

  return (
    <App>
      <Layout className="layout bg-secondary overflow-hidden">
        <Sider
          className={` ${collapsed && "collapsed"}`}
          trigger={null}
          collapsible
          collapsed={collapsed}>
          <div className="flex h-screen sticky top-0  flex-col">
            <div className="h-18 py-2 flex justify-center">
              <Link to={"/histories"}>
                <img className={`mx-auto ${!collapsed ? "hidden" : ""}`} src={logo} title="Logo" />
                <img
                  className={`mx-auto ${collapsed ? "hidden" : ""}`}
                  src={logoFull}
                  title="logo"
                />
              </Link>
            </div>
            <div className="flex-1">
              <Menu
                selectedKeys={[currentPath]}
                style={{ padding: "5px" }}
                theme="dark"
                className="bg-secondary"
                items={menuItems}
              />
              <div className={`${!collapsed ? "mx-6" : "mx-8"} text-[#B3B3B3] cursor-pointer flex gap-6 items-center font-medium`} onClick={() => window.open(`${options?.ic_home_page}`, '_blank', 'noopener noreferrer')}>
                <HomeOutlined style={{ fontSize: !collapsed ? "20px" : "14px", color: "#B3B3B3" }} />
                {
                  !collapsed && <span>IC Home Page</span>
                }
              </div>
            </div>
            <div className="mb-[30px] mx-[22px]">
              <div>
                <div
                  onClick={() => setCollapsed(!collapsed)}
                  className={`cursor-pointer mb-10 text-white flex items-center overflow-hidden`}>
                  {collapsed ? (
                    <ChervonDoubleRightIcon
                      onClick={() => setCollapsed(!collapsed)}
                      className={`cursor-pointer leading-none`}
                    />
                  ) : (
                    <>
                      <ChervonDoubleLeftIcon className="leading-none mr-5" />
                      <span className="whitespace-nowrap animate-fadeIn delay-100 font-medium">
                        Thu gọn
                      </span>
                    </>
                  )}
                </div>
                <div
                  className={`cursor-pointer mb-10 text-white flex items-center overflow-hidden `}
                  onClick={handleClick}>
                  <Avatar
                    className="cursor-pointer"
                    icon={<Icon name="Avatar" />}
                    src={account.profile.avatar}
                    size={24}
                  />
                  {!collapsed && (
                    <span className="whitespace-nowrap animate-fadeIn delay-100 block ml-5 font-medium">
                      {account.profile.fullName}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Sider>
        <Layout>
          <Content className="ml-4">
            <Suspense
              fallback={
                <div className="relative">
                  <Spin className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2/" />
                </div>
              }>
              <h1>This is Test CICD</h1>
              <Outlet />
            </Suspense>
            <Stringee />
          </Content>
        </Layout>
      </Layout>

    </App>
  );
}
